.Aprcontainer
{
    .modal-dialog {
        position: fixed;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 410px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 25px;
        border: 1px solid #e3d32d;
        background: #111326;
        color: #fff;
       
        z-index: 4;
    }
    button.close {
        font-size: 0;
        background: none;
        box-shadow: none;
        border: none;
        position: absolute;
        right: 0;
        padding: 12px;

        span.sr-only:before {
                content: "x";
                font-size: 24px;
                color: #e3d32d;
                position: relative;
                right: 0;
            }
      }
    
    .modal-header {
        text-align: center;
        width: 100%;
        padding: 10px 0;
        font-family: "Fredoka One",cursive;
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        margin-right: 5px;
        border-bottom: 1px solid #e3d32d;
        padding: 40px 10px;
    }
    .modal-body {
        padding: 30px 20px;
    }
}
  